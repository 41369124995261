const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  designationData: [],
  reportingManager: [],
  usersBranchWise: [],
  BDExecutive: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_USER":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_DATA_All_ACTIVE_USER":
      return {
        ...state,
        reportingManager: action.data,
        allUsers: action.data,
      };

    case "GET_DATA_BD_EXECUTIVE_USER":
      return {
        ...state,
        BDExecutive: action.data,
      };

    case "GET_DATA_USERS_BRANCH_WISE":
      return {
        ...state,
        usersBranchWise: action.data,
      };

    case "CREATE_USER":
      return { ...state };
    case "UPDATE_USER":
      return { ...state };
    // case 'GET_USER_ACCESS':
    //   return {
    //     ...state,
    //     userAccess: action.data
    //   }

    default:
      return { ...state };
  }
};
export default userReducer;
