// ** Checks if an object is empty (returns boolean)
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AWS from "aws-sdk";
import firebase from "@firebase/app";
import { NotificationManager } from "react-notifications";

export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

const MySwal = withReactContent(Swal);
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: "login session has been expired ?",
    text: "login again to continue accessing your account !",
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "Yes, logout it!",
    customClass: {
      confirmButton: "btn btn-danger",
      cancelButton: "btn btn-outline-danger ml-1",
    },
    allowOutsideClick: false,
    buttonsStyling: false,
  }).then(function (result) {
    if (result.value) {
      firebase.auth().signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/";
    }
  });
};
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase
    .auth()
    .currentUser.getIdToken(false)}`;
  return config;
});
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      // handleConfirmText();
    } else {
      return Promise.reject(error);
    }
  }
);
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export { Axios, AxiosInstance };

//-------------------image upload on s3--------------------------//
AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_S3_REGION,
});

const s3 = new AWS.S3({
  apiVersion: "2012-10-17",
  params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME },
});

//upload image on s3 bucket  directly
export const uploadImageToS3 = (file) => {
  const params = {
    Key: `${Date.now()}_${file.name}`,
    Body: file,
    ACL: "public-read",
    ContentType: file.type,
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        resolve("");
      } else {
        resolve(data.Location);
      }
    });
  });
};

export const UPLOAD_IMAGES_VIDEOS = async (
  event,
  uploadedImages,
  setImages,
  setShowImagePreviews,
  setLoading
) => {
  setLoading(true);

  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
    fieldName: dataArray.getAttribute("field-name"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    setLoading(false);
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    setLoading(false);
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  const allowPdf = data.fieldName === "logo" ? null : "pdf";

  if (
    extension === "png" ||
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === allowPdf || // Allow PDF files
    event.target.accept.includes("video/") // Allow video files
  ) {
    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: {
        Bucket: "aspl-crm",
      },
    });

    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    // const maxSize = {
    //   width: 304,
    //   height: 88,
    // };

    const fileSizeLimit = data.fieldName === "logo" ? 512000 : 5000000;

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      const isImage = ["png", "jpg", "jpeg", "pdf"].includes(extension);
      const isVideo = ["mp4", "mkv", "mov"].includes(extension);

      if (
        (isImage && file.size <= fileSizeLimit) ||
        (isVideo && file.size <= 51000000)
      ) {
        
        const filePath = `vendor/${
          data.subfolderName
        }/${date}/${uuidv4()}.${extension}`;

        try {
          await s3
            .upload({
              Key: filePath,
              Body: file,
              ACL: "public-read",
            })
            .promise();
          const fileUrl = `https://aspl-crm.s3.ap-south-1.amazonaws.com/${filePath}`;
          NotificationManager.success("File uploaded successfully", "Success");

          // You can use setState or some other state management mechanism to update UI
          const updatedImages = [...uploadedImages, fileUrl];
          setImages(updatedImages);
          setShowImagePreviews(true);
          event.target.value = ""

        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
        }
        // } else {
        //   dataArray.value = "";
        //   NotificationManager.error(
        //     `Image dimensions should be ${maxSize.width}x${maxSize.height}`,
        //     `File name: ${file.name}`
        //   );
        // }
        // };
        // };

        // reader.readAsDataURL(file);
      } else {
        dataArray.value = "";
        const sizeUnit = data.fieldName === "logo" ? "KB" : "MB";

        if (isImage) {
          NotificationManager.error(
            `Image size needs to be less than or equal to ${
              fileSizeLimit / (data.fieldName === "logo" ? 1000 : 5000000)
            }${sizeUnit}`,
            `File name: ${file.name}`
          );
        } else if (isVideo) {
          NotificationManager.error(
            `Video size needs to be less than or equal to 50 MB`, // Assuming 51000000 bytes is 50 MB
            `File name: ${file.name}`
          );
        }
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error(
      `Only JPG, PNG, JPEG ${
        allowPdf === "pdf" ? ", PDF, or video" : ""
      } file types are allowed`,
      "Error"
    );
  }

  setLoading(false);
};

//Delete file from S3.
export const deleteImageFromS3 = (filepath) => {
  const bucketName = "aspl-crm";
  const s3 = new AWS.S3({
    apiVersion: "2006-03-01",
  });

  const deleteFile = (filePath) => {
    const params = {
      Bucket: bucketName,
      Key: filePath.split("/").slice(3).join("/"),
    };

    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.log("Check if you have sufficient permissions : " + err);
      }
    });
  };

  filepath.forEach((filePath) => {
    deleteFile(filePath);
  });
};

//Upload Media file to S3.
export const UPLOAD_IMAGES = async (
  event,
  setMediaFile,
  setShowPreview,
  setIsVideo,
  setLoading
) => {
  setLoading(true);

  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
    fieldName: dataArray.getAttribute("field-name"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    setLoading(false);
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    setLoading(false);
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (setIsVideo) {
    if (extension === "mp4" || extension === "mkv") {
      setIsVideo(true);
    } else {
      setIsVideo(false);
    }
  }

  const allowPdf = data.fieldName === "logo" ? null : "pdf";

  if (
    extension === "png" ||
    extension === "jpg" ||
    extension === "jpeg" ||
    extension === allowPdf || // Allow PDF files
    event.target.accept.includes("video/") // Allow video files
  ) {
    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: {
        Bucket: "aspl-crm",
      },
    });

    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    // const maxSize = {
    //   width: 304,
    //   height: 88,
    // };

    const fileSizeLimit = data.fieldName === "logo" ? 512000 : 1000000;

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      const isImage = ["png", "jpg", "jpeg", "pdf"].includes(extension);
      const isVideo = ["mp4", "mkv", "mov"].includes(extension);

      if (
        (isImage && file.size <= fileSizeLimit) ||
        (isVideo && file.size <= 51000000)
      ) {
        // const image = new Image();

        // Read the image content as a data URL
        // const reader = new FileReader();
        // reader.onload = function (e) {
        //   image.src = e.target.result;

        // Check image dimensions after it's loaded
        // image.onload = async function () {
        // if (
        //   image.width === maxSize.width &&
        //   image.height === maxSize.height
        // ) {
        const filePath = `vendor/${
          data.subfolderName
        }/${date}/${uuidv4()}.${extension}`;

        try {
          await s3
            .upload({
              Key: filePath,
              Body: file,
              ACL: "public-read",
            })
            .promise();
          const fileUrl = `https://aspl-crm.s3.ap-south-1.amazonaws.com/${filePath}`;
          NotificationManager.success("File uploaded successfully", "Success");

          // You can use setState or some other state management mechanism to update UI
          setMediaFile(fileUrl);
          setShowPreview(true);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
        }
        // } else {
        //   dataArray.value = "";
        //   NotificationManager.error(
        //     `Image dimensions should be ${maxSize.width}x${maxSize.height}`,
        //     `File name: ${file.name}`
        //   );
        // }
        // };
        // };

        // reader.readAsDataURL(file);
      } else {
        dataArray.value = "";
        const sizeUnit = data.fieldName === "logo" ? "KB" : "MB";

        if (isImage) {
          NotificationManager.error(
            `Image size needs to be less than or equal to ${
              fileSizeLimit / (data.fieldName === "logo" ? 1000 : 1000000)
            }${sizeUnit}`,
            `File name: ${file.name}`
          );
        } else if (isVideo) {
          NotificationManager.error(
            `Video size needs to be less than or equal to 50 MB`, // Assuming 51000000 bytes is 50 MB
            `File name: ${file.name}`
          );
        }
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error(
      `Only JPG, PNG, JPEG ${
        allowPdf === "pdf" ? ", PDF, or video" : ""
      } file types are allowed`,
      "Error"
    );
  }

  setLoading(false);
};

//Upload Thumbnail image to s3.
export const UPLOAD_THUMBNAIL_IMAGE = async (
  event,
  setThumbnailFile,
  setShowPreviewThumbnail
) => {
  const dataArray = event.target;
  const data = {
    id: dataArray.getAttribute("id"),
    progressBar: dataArray.getAttribute("data-progress-bar"),
    appendClass: dataArray.getAttribute("data-append-class"),
    commonClass: dataArray.getAttribute("data-common-class"),
    subfolderName: dataArray.getAttribute("data-subfolder-name"),
    dataIsMultiple: dataArray.getAttribute("data-is-multiple"),
  };

  if (
    data.dataIsMultiple === "false" &&
    document.querySelectorAll("." + data.commonClass).length > 0
  ) {
    return false;
  }

  const fileUploadPath = dataArray.value;

  if (fileUploadPath === "") {
    return;
  }

  const extension = fileUploadPath
    .substring(fileUploadPath.lastIndexOf(".") + 1)
    .toLowerCase();

  if (extension === "png" || extension === "jpg" || extension === "jpeg") {
    // const bucketName = extension === "pdf" ? "luke-images" : "luke-images-data";
    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: {
        Bucket: "aspl-crm",
      },
    });

    // document.getElementById(data.progressBar).style.width = "0%";

    const today = new Date();
    const date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();

    for (let i = 0; i < event.target.files.length; i++) {
      const imageFile = event.target.files[i];

      if (imageFile.size <= 1000000) {
        const filePath = `vendor/${
          data.subfolderName
        }/${date}/${uuidv4()}.${extension}`;

        try {
          await s3
            .upload({
              Key: filePath,
              Body: imageFile,
              ACL: "public-read",
            })
            .promise();

          const fileUrl = `https://aspl-crm.s3.ap-south-1.amazonaws.com/${filePath}`;
          NotificationManager.success("File uploaded successfully", "Success");

          // You can use setState or some other state management mechanism to update UI
          setThumbnailFile(fileUrl);
          setShowPreviewThumbnail(true);
        } catch (err) {
          NotificationManager.error("Something went wrong!", "Error");
          console.error(err);
        }
      } else {
        dataArray.value = "";
        NotificationManager.error(
          "File size needs to be less than or equal to 1mb",
          "File name: " + imageFile.name
        );
      }
    }
  } else {
    dataArray.value = "";
    NotificationManager.error(
      "Only JPG or PNG or JPEG file types are allowed",
      "Error"
    );
  }
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
