/**
 * User Block Section
 */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";

// redux action
import { handleLogout } from "Redux/actions/auth";

function HeaderUserBlock() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  /*
   * Logout User
   */
  const logoutUser = () => {
    dispatch(handleLogout());
    setAnchorEl(null);
  };
  //   const [userName, setUserName] = useState("");
  //   const [userType, setUserType] = useState("");
  //   useEffect(() => {
  //     const userData = localStorage.getItem("userData");
  //     const userInfo = JSON.parse(userData);
  //     setUserName(userInfo.name);
  //     setUserType(userInfo.user_type);
  //   }, []);

  const open = Boolean(anchorEl);
  return (
    <div>
      <Tooltip title="Sign Out" placement="bottom">
        <IconButton
          aria-describedby={open ? "simple-popper" : null}
          variant="contained"
          style={{ padding: "6px" }}
          onClick={logoutUser}
        >
          {/* <Avatar alt="user-thumb" className={classes.avatar} src={require('assets/Images/avatars/user-4.jpg').default} /> */}
          <i className="material-icons-outlined">power_settings_new</i>
        </IconButton>
      </Tooltip>
      {/* <Popover
				id="simple-popper"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Fragment>
					<List className={`${classes.root} top-dropdown-menu`}
						component="nav"
						aria-labelledby="nested-list-subheader"
						subheader={
							<ListSubheader component="div" id="nested-list-subheader">
								<div className="dropdown-header user-info  text-center">
									<Avatar alt="user-thumb" className={classes.large} src={require('assets/Images/avatars/user-4.jpg').default} />
									<Typography variant="body2" style={{'text-transform': 'capitalize'}}>{userName}</Typography>
									<Typography variant="subtitle2">{userType}</Typography>
									<Button className="btn primary-bg-btn" component={Link} to="/app/user-settings" variant="outlined" color="primary">
										Manage your account
									</Button>
								</div>
							</ListSubheader>
						}
					>
						<ListItem component="div" className="top-dropdown-menu--item d-block text-center">
							<Button variant="contained" color="primary" onClick={logoutUser}>
								Sign out
     						</Button>
						</ListItem>
					</List>
				</Fragment>
			</Popover> */}
    </div>
  );
}

export default HeaderUserBlock;
