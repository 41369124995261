/**
 * Router service file
 */

// Async component
import {
  AsyncCityMasterComponent,
  AsyncDashboardComponent,
  AsyncDepartmentMasterComponent,
  AsyncDesignationMasterComponent,
  AsyncLeadSourceMasterComponent,
  AsyncLeadsFormMasterComponent,
  AsyncProductMasterComponent,
  AsyncSettingMasterComponent,
  AsyncTemplateTriggerMasterComponent,
  AsyncUserMasterComponent,
  AsyncWaDraftMasterComponent,
  AsyncLeadsReportsComponent,
  AsyncLeadsImportComponent,
  AsyncCallingPerformanceReportComponent,
  AsyncCallingHistoryReportComponent,
  AsyncIndustryMasterComponent,
  AsyncRemarkMasterComponent,
  AsyncWaDraftCategoryMasterComponent,
  AsyncCompanyMasterComponent,
  AsyncMetaFieldsMasterComponent,
  AsyncUserCallLogReportComponent,
  AsyncBranchMasterComponent,
  AsyncShiftMasterComponent,
  AsyncWeeklyOffMasterComponent,
  AsyncHolidayMasterComponent,
  AsyncAttendanceReportMasterComponent,
  AsyncMonthlyAttendanceReportMasterComponent,
  AsyncAttendanceSummaryReportComponent,
  AsyncHrSettingMasterComponent,
  // AsyncWeekOffRosterMasterComponent,
  AsyncLeaveApprovalMasterComponent,
  AsyncLocationTrackingComponent,
  AsyncUserTrackingComponent,
  AsyncDeveloperMasterComponent,
  AsyncProjectMasterComponent,
  AsyncAssociateMasterComponent,
  AsyncBankMasterComponent,
  AsyncBankBranchMasterComponent,
  AsyncDeveloperLeadsReportsComponent,
} from "routes/ComponentLoader";

const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

const routes = [
  //Dashboard's routes & permissions.
  {
    path: "dashboard",
    component: AsyncDashboardComponent,
    meta: {
      permission: true,
      // permission: (permission && permission.Dashboard !== undefined ? permission && permission.Dashboard.includes("dashboard") : false),
    },
  },
  //Master's routes & permissions.
  {
    path: "master/user",
    component: AsyncUserMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("user")
          : false,
    },
  },
  {
    path: "master/designation",
    component: AsyncDesignationMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("designation_master")
          : false,
    },
  },
  {
    path: "master/department",
    component: AsyncDepartmentMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("department_master")
          : false,
    },
  },
  {
    path: "master/city",
    component: AsyncCityMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("city_master")
          : false,
    },
  },
  {
    path: "master/leadsource",
    component: AsyncLeadSourceMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("lead_source_master")
          : false,
    },
  },
  {
    path: "master/industry",
    component: AsyncIndustryMasterComponent,
    meta: {
      permission: true,
      // permission:
      //   permission && permission.Master !== undefined
      //     ? permission && permission.Master.includes("industry_master")
      //     : false,
    },
  },
  {
    path: "master/product",
    component: AsyncProductMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("product_master")
          : false,
    },
  },
  {
    path: "master/whatsappdraft",
    component: AsyncWaDraftMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("wa_draft_master")
          : false,
    },
  },
  {
    path: "master/templatetrigger",
    component: AsyncTemplateTriggerMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("template_trigger_master")
          : false,
    },
  },
  {
    path: "master/remark",
    component: AsyncRemarkMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("remark_master")
          : false,
    },
  },
  {
    path: "master/setting",
    component: AsyncSettingMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("setting_master")
          : false,
    },
  },
  {
    path: "master/whatsapp-draft-category",
    component: AsyncWaDraftCategoryMasterComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("wa_draft_category_master")
          : false,
    },
  },
  {
    path: "master/company",
    component: AsyncCompanyMasterComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("company_master")
          : false,
    },
  },
  {
    path: "master/meta-fields",
    component: AsyncMetaFieldsMasterComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("metafields_master")
          : false,
    },
  },
  //Leads's routes & permissions.
  {
    path: "leads/leadsform",
    component: AsyncLeadsFormMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Leads !== undefined
          ? permission && permission.Leads.includes("leads_form")
          : false,
    },
  },
  {
    path: "leads/leads-import",
    component: AsyncLeadsImportComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.Leads !== undefined
          ? permission && permission.Leads.includes("leads_import")
          : false,
    },
  },

  // report routes & permissions.
  {
    path: "reports/leads-report",
    component: AsyncLeadsReportsComponent,
    meta: {
      permission: true,
      // permission: (permission && permission.Leads !== undefined ? permission && permission.Leads.includes("leads_report") : false),
    },
  },
  {
    path: "reports/calling-performance-report",
    component: AsyncCallingPerformanceReportComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.Reports !== undefined
          ? permission &&
          permission.Reports.includes("calling_performance_report")
          : false,
    },
  },
  {
    path: "reports/calling-history-report",
    component: AsyncCallingHistoryReportComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.Reports !== undefined
          ? permission && permission.Reports.includes("calling_history_report")
          : false,
    },
  },
  {
    path: "reports/user-call-log-report",
    component: AsyncUserCallLogReportComponent,
    meta: {
      permission: permission && permission.Reports !== undefined
        ? permission && permission.Reports.includes("user_call_log_report")
        : false,
    },
  },

  // Branch Master routes & permissions.
  {
    path: "master/branch",
    component: AsyncBranchMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("branch_master")
          : false,
    },
  },

  // Attendance

  {
    path: "Attendance/ShiftMaster",
    component: AsyncShiftMasterComponent,
    meta: {
      //permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("shift")
        : false,
    },
  },

  {
    path: "Attendance/WeeklyOffMaster",
    component: AsyncWeeklyOffMasterComponent,
    meta: {
      // permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("weekly_off")
        : false,
    },
  },
  {
    path: "Attendance/HoliydayMaster",
    component: AsyncHolidayMasterComponent,
    meta: {
      // permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("holiday")
        : false,
    },
  },

  {
    path: "Attendance/AttendanceReport",
    component: AsyncAttendanceReportMasterComponent,
    meta: {
      // permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("daily_report")
        : false,
    },
  },
  {
    path: "Attendance/monthlyAttendanceReport",
    component: AsyncMonthlyAttendanceReportMasterComponent,
    meta: {
      //permission: true,
      permission:
        permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("monthly_report")
          : false,
    },
  },

  {
    path: "setting/hr-setting",
    component: AsyncHrSettingMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Setting !== undefined
          ? permission && permission.Setting.includes("hr_setting")
          : false,
    },
  },

  // {
  //   path: "attendance/WeeklyOffRosterMaster",
  //   component: AsyncWeekOffRosterMasterComponent,
  //   meta: {
  //     // permission: true,
  //     permission: permission && permission.Attendance !== undefined
  //       ? permission && permission.Attendance.includes("weekly_off_roster")
  //       : false,
  //   },
  // },

  {
    path: "attendance/AttendanceSummaryReport",
    component: AsyncAttendanceSummaryReportComponent,
    meta: {
      //permission: true,
      permission:
        permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("summery_report")
          : false,
    },
  },

  {
    path: "attendance/LeaveApprovalMaster",
    component: AsyncLeaveApprovalMasterComponent,
    meta: {
      // permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("leave_approval")
        : false,
    },
  },

  {
    path: "tracking/location-tracking",
    component: AsyncLocationTrackingComponent,
    meta: {
      permission:
        permission && permission.LiveTracking !== undefined
          ? permission &&
          permission.LiveTracking.includes("location_tracking")
          : false,
    },
  },
  {
    path: "tracking/user-tracking",
    component: AsyncUserTrackingComponent,
    meta: {
      permission:
        permission && permission.LiveTracking !== undefined
          ? permission &&
          permission.LiveTracking.includes("user_tracking")
          : false,
    },
  },
  {
    path: "master/developer",
    component: AsyncDeveloperMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("developer_master")
          : false,
    },
  },

  {
    path: "master/project",
    component: AsyncProjectMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("project_master")
          : false,
    },
  },

  {
    path: "master/associate",
    component: AsyncAssociateMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("associate_master")
          : false,
    },
  },
  {
    path: "master/bank",
    component: AsyncBankMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("bank_master")
          : false,
    },
  },
  {
    path: "master/bank-branch",
    component: AsyncBankBranchMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("bank_branch_master")
          : false,
    },
  },
  {
    path: "reports/developer-leads-report",
    component: AsyncDeveloperLeadsReportsComponent,
    meta: {
      permission: 
      permission && permission.Reports !== undefined
      ? permission && permission.Reports.includes("developer_leads_report")
      : false,
    },
  },
];

export default routes;
