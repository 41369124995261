const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
  }
  
  const waDraftReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_WHATSAPP_DRAFT':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'CREATE_WHATSAPP_DRAFT':
        return { ...state }
      case 'UPDATE_WHATSAPP_DRAFT':
        return { ...state }
      
      default:
        return { ...state }
    }
  }
  export default waDraftReducer
  