const initialState = {
    data: {},
    params: {},
  }
  
  const callingHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_CALLING_HISTORY_REPORT':
        return {
          ...state,
          data: action.data,
          params: action.params
        }
       
      default:
        return { ...state }
    }
  }
  export default callingHistoryReducer
  