/**
 * Redux App Settings Actions
 */
import {
  COLLAPSED_SIDEBAR,
  MINI_SIDEBAR,
  DARK_MODE,
  RTL,
  HORIZONTAL_MENU,
  CHOOSE_THEME,
  SET_LANGUAGE,
  NOTIFICATION_SIDEBAR,
} from "./Types";
import ReportFormData from "../../assets/Data/ReportsFormData.json";
/**
 * Redux Action To Emit Collapse Sidebar
 */
export const collapsedSidebarAction = (isCollapsed) => ({
  type: COLLAPSED_SIDEBAR,
  isCollapsed,
});

/**
 * Redux Action To Emit Mini Sidebar
 */
export const miniSidebarAction = (isMiniSidebarActive) => ({
  type: MINI_SIDEBAR,
  isMiniSidebarActive,
});

/**
 * Redux Action To Emit Dark Mode
 */
export const darkModeAction = (isActive) => ({
  type: DARK_MODE,
  isActive,
});

/**
 * Redux Action To Emit RTL Layout
 */
export const rtlAction = (isActive) => ({
  type: RTL,
  isActive,
});

/**
 * Redux Action To Set Language
 */
export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
});

/**
 * Redux Action To Emit Rtl Layout
 *  @param {*boolean} isRtlLayout
 */

/**
 * Redux Action To change Layout
 */
export const horizontalMenuAction = (isActive) => ({
  type: HORIZONTAL_MENU,
  isActive,
});

/**
 * Redux Action To change Theme color
 */
export const chooseThemeAction = (theme) => ({
  type: CHOOSE_THEME,
  theme,
});

/**
 * Redux Action To Emit Collapse Sidebar
 */
export const notificationSidebarAction = (isNotification) => ({
  type: NOTIFICATION_SIDEBAR,
  isNotification,
});

// Action for get information by url for Sidebar.
export const getInfoByUrl = (url, setLoader) => {
  return (dispatch) => {
    setLoader(true);

    // Initialize a variable to store the matching data
    let matchedData = {};

    // Iterate through the ReportFormData array
    for (const formData of ReportFormData) {
      if (formData.url === url) {
        matchedData = formData;
        break; // Exit the loop if a match is found
      }
    }

    // Dispatch the matched data to the reducer
    dispatch({
      type: "GET_INFORMATION",
      data: matchedData,
    });

    setLoader(false);
  };
};
