/**
 * Firebase details, you need to add your firebase project details here
 */
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIB_API_KEY,
	authDomain: process.env.REACT_APP_FIB_AUTH_DOMAIN,
	// databaseURL: process.env.REACT_APP_FIB_DATABASE,
	projectId: process.env.REACT_APP_FIB_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIB_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIB_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIB_APP_ID,
	//measurementId: "G-6LG67VEQF9"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const database = firebase.database();

export {
	auth,
	googleAuthProvider,
	githubAuthProvider,
	facebookAuthProvider,
	twitterAuthProvider,
	database,
	firebase
};