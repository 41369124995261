const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    allActiveRemark: [],
  }
  
  const remarkReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_REMARK':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_DATA_ALL_ACITVE_REMARK':
        return {
          ...state,
          allActiveRemark: action.data,
        }
      case 'CREATE_REMARK':
        return { ...state }
      case 'UPDATE_REMARK':
        return { ...state }
      
      default:
        return { ...state }
    }
  }
  export default remarkReducer
  