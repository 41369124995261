const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  importResDataAuto: {},
  importResDataManual: {},
};

const leadsImportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IMPORT_LEADS_AUTO_ASSIGN":
      return {
        ...state,
        importResDataAuto: action.data,
      };

    case "IMPORT_LEADS_MANUAL":
      return {
        ...state,
        importResDataManual: action.data,
      };

    default:
      return { ...state };
  }
};
export default leadsImportReducer;
