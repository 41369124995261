const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    allActiveBranch: [],
  }
  
  const branchMasterReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_BRANCH':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case 'GET_DATA_ALL_ACITVE_BRANCH':
          return {
            ...state,
            allActiveBranch: action.data,
          }
      case 'CREATE_BRANCH':
        return { ...state }
      case 'UPDATE_BRANCH':
        return { ...state }
      case 'DELETE_BRANCH':
        return { ...state }
      default:
        return { ...state }
    }
  }
  export default branchMasterReducer
  