/**
 * Nav Menu Item
 */
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

// Intl messages

function NavMenuItem(props) {
  const { menu } = props;

  return (
    <Fragment>
      {menu.child_routes !== null ? (
        <li className="menu-item-has-child">
          {menu.child_routes.map((subMenu, subKey) => {
            if (!subMenu.third_child_routes) {
              return (
                <li key={subKey}>
                  <NavLink
                    to={subMenu.path}
                    className="nav-link no-arrow"
                    activeClassName="active"
                  >
                    <i className="material-icons-outlined">{subMenu.icon}</i>
                    <span>{subMenu.menu_title}</span>
                  </NavLink>
                </li>
              );
            }
            return (
              <li key={subKey} className="menu-item-has-child">
                <a
                  href={window.location.href}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span>oiuoiuoiuuoiuoiu</span>
                </a>
                <ul className="list-unstyled sub-menu hjh">
                  {subMenu.third_child_routes.map((nestedMenu, nestedKey) => (
                    <li key={nestedKey}>
                      <NavLink to={nestedMenu.path}>
                        <span>{nestedMenu.menu_title}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
          {/* </ul> */}
        </li>
      ) : (
        <li>
          <NavLink to={menu.path}>
            <i className="material-icons-outlined">{menu.icon}</i>
            <span>{menu.menu_title}</span>
          </NavLink>
        </li>
      )}
    </Fragment>
  );
}

export default NavMenuItem;
