const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  allActiveMetaFields: [],
};

const metaFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_META_FIELDS":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };

    case "GET_DATA_ALL_ACITVE_META_FIELDS":
      return {
        ...state,
        allActiveMetaFields: action.data,
      };

    case "CREATE_META_FIELDS":
      return { ...state };
    case "UPDATE_META_FIELDS":
      return { ...state };
    case "DELETE_META_FIELDS":
      return { ...state };

    default:
      return { ...state };
  }
};
export default metaFieldsReducer;
