const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  userData: [],
  trackUserData: []

}

const locationTracking = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_TRACKING_USER':
      return {
        ...state,
        trackUserData: action.data,
      };


    default:
      return { ...state }
  }
}
export default locationTracking
