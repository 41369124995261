
import { Axios } from '../../util/index'
import { NotificationManager } from 'react-notifications';

//-----------get user list with paginated data--------------------------// 
export const getInformationList = (params, setisLoader) => {
    setisLoader(true)
    return dispatch => {
        Axios.get(`/api/master/form-information/paginated?page=${params.page}&limit=${params.perPage}&search=${params.search}`).then(response => {
            dispatch({
                type: 'GET_DATA_INFORMATION',
                allData: response.data.data.total,
                data: response.data.data.docs,
                totalPages: response.data.data.pages,
                params
            })
        })
            .catch(error => {
                dispatch({
                    type: 'GET_DATA_INFORMATION',
                    allData: 0,
                    data: [],
                    totalPages: 0,
                    params
                })
            }).finally(() => {
                setisLoader(false)
            })
    }
}


//-----------------create new information--------------------------//
export const createNewInformation = (data, props, setisLoader, resateData) => {
    return (dispatch, getStore) => {
        Axios.post(`/api/master/form-information`, {
            url: data.url,
            short_line_description: data.short_line_description,
            description: data.description,
            note: data.note,
        })
            .then(response => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);
                }
                else {
                    props.onMasterSave();
                    resateData();
                    NotificationManager.success(response.data.message);
                    return dispatch => {
                        dispatch(
                            {
                                type: 'CREATE_INFORMATION',
                                data: []
                            }
                        )
                    }
                }
            })

            .then(() => dispatch(getInformationList(getStore().formInfoReducer.params, setisLoader)))
            .catch(function (error) {
                NotificationManager.error(error.response && error.response.data ? error.response.data.message : 'Something Went Wrong !');
            })
    }
}

// ------------------update information------------------------//
export const updateInformation = (data, props, setisLoader) => {
    return (dispatch, getStore) => {
        Axios.put(`/api/master/form-information/${props.preloadValue.detail._id}`, {
            url: data.url,
            short_line_description: data.short_line_description,
            description: data.description,
            note: data.note,
        })
            .then(response => {
                if (response.data.status === false) {
                    NotificationManager.error(response.data.message);

                } else {
                    NotificationManager.success(response.data.message);
                    props.onMasterSave();
                    return dispatch => {
                        dispatch(
                            {
                                type: 'UPDATE_INFORMATION',
                                data
                            }
                        )
                    }
                }
            })
            .then(() => dispatch(getInformationList(getStore().formInfoReducer.params, setisLoader)))
            .catch(function (error) {

            })


    }
}


